export const config = {
  title: 'Deera',
  name: "Deera",
  image: '',
  logo: 'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Deera/StoreGallery/1665829151202%20-%20Sapphire%202%20Crop.png',
  DOMAIN_NAME : 'https://deera.ecbee.net',
  USER_ID : 17,
  STORE_ID: 1,
  ORGANIZATION_ID:10,
  default_product_image: "",
  product_path:"/productListing",
  showTableBookingBtn:false,
  noImageAvailable:"https://d18xkwaipu1whh.cloudfront.net/CommonAsset/No_Image_old.jpg",
  pattern1: "!@#$%^",
  pattern2: "8172",
  pattern3: "havensampoorna",

  HOME_SETTINGS: {
    banner: [
      {
        name: "Home Banner",
        image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/banner.jpg",
        quote: "<h3>Good Food <br> Good Feelings</h3><p>with <span>Haven Sampoorna</span></p>",
        button: {
          name: "CHECK OUT OUR MENU",
          link: "product"
        }
      }
    ],
    about_st: [
      {
        title: "FAST DELIVERY",
        image: "assets/images/vendor/home1.svg",
        text: "Everything you order at HavenSampoorna will be quickly delivered to you"
      },
      {
        title: "FRESH FOOD",
        image: "assets/images/vendor/home2.svg",
        text: "We used only the best ingredients to cook the tasty food for you"
      },
      {
        title: "EXPERIENCED CHEFS",
        image: "assets/images/vendor/home3.svg",
        text: "Our staff consists of chefs and cooks with years of experience"
      },
      {
        title: "A VARIETY OF DISHES",
        image: "assets/images/vendor/home4.svg",
        text: "In our menu you will find a wide variety of dishes, desserts, drinks"
      }
    ],
    gutters: [
      {
        image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img1.jpg"
      },
      {
        image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img2.jpg"
      },
      {
        image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img3.jpg"
      },
      {
        image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/img4.jpg"
      }
    ],
    testimonials: [
      {
        title: "LOVE FROM HAVEN SAMPOORNA FAM",
        comment: "I got a very quick of delivery the food. The food is awesome. <br>It's excellent quality and value for your money.Highly recommended. Good job team Haven Sampoorna",
        name: "Swati Savera"
      }
    ],
    float_button: {
      show: false,
      image: "assets/images/tams/online.svg",
      link: "/product"
    }
  },
  menu: [
    {
      id: "home",
      label: "Home",
      link: "/"
    },
    {
      id: "menus",
      label: "Products",
      link: "product"
    },
    {
      id: "about",
      label: "About",
      link: "/about-us"
    },
    {
      id: "contact",
      label: "Contact",
      link: "contact-us"
    }
  ],
  footer: [
    {
      id: "about",
      label: "About",
      link: "about-us"
    },
    {
      id: "contact",
      label: "Contact",
      link: "contact-us"
    },
    ,
    {
      id: "faq",
      label: "FAQ",
      link: "FAQs"
    },
    {
      id: "termsandconditions",
      label: "Terms & conditions",
      link: "terms-and-condition"
    },
    {
      id: "privacypolicy",
      label: "Privacy Policy",
      link: "privacy-policy"
    }
  ]
};
